import axios from 'axios'

export default {
  runTranslation ({ commit, state }) {
    commit('setStateProperty', { property: 'runningTranslation', value: true })
    commit('setStateProperty', { property: 'translationGeoJson', value: {} })
    const translationGeoJson = {
      type: 'FeatureCollection',
      features: []
    }
    state.datasetGeoJson.features.forEach(datasetFeature => {
      const translatedValue = (datasetFeature.properties[state.datasetHeader] - state.slopeIntercept.b) / state.slopeIntercept.m
      const translatedFeature = {
        type: 'Feature',
        properties: {},
        geometry: datasetFeature.geometry
      }
      translatedFeature.properties[state.samplesDatasetHeader + 'Translated'] = translatedValue
      translationGeoJson.features.push(translatedFeature)
    })
    setTimeout(() => {
      commit('setStateProperty', { property: 'translationGeoJson', value: translationGeoJson })
      commit('setStateProperty', { property: 'runningTranslation', value: false })
      // commit('setAlert', { active: true, message: 'Success! Translation complete. Choose a location to save the new dataset.', level: 'alert-success', timeout: 4000 })
      commit('setStateProperty', { property: 'mode', value: 'save' })
    }, 3000)
  },
  post ({ commit, state }) {
    commit('setStateProperty', { property: 'posting', value: true })
    const postName = state.post.name
    const postGeoJson = JSON.stringify(state.translationGeoJson)
    const postObject = {
      name: postName,
      createdBy: 'translation_plugin',
      farmId: state.farm.id,
      geoJson: postGeoJson,
      folderId: state.folder.id,
      vizSettings: {
        colorHeader: state.samplesDatasetHeader + 'Translated',
        colorBy: 'valueBased'
      }
    }
    axios.post('https://api.efficientvineyard.com/post/geojson', postObject).then(response => {
      if (response.data.success) {
        commit('setStateProperty', { property: 'posting', value: false })
        commit('setAlert', { active: true, message: 'This new dataset has been added to your myEV farm!', level: 'alert-success', timeout: 4000 })
      } else {
        commit('setAlert', { active: true, message: 'There was an issue posting your grid to myEV. The dataset may have been too large. Consider downloading the grid directly and manually uploading to myEV.', level: 'alert-danger', timeout: 8000 })
      }
    }).catch(err => {
      console.log(err)
      commit('setAlert', { active: true, message: 'There was an issue posting your grid to myEV. The dataset may have been too large. Consider downloading the grid directly and manually uploading to myEV.', level: 'alert-danger', timeout: 12000 })
    })
  },
  download ({ commit, state }) {
    let fileContents = JSON.stringify(state.translationGeoJson)
    fileContents = [fileContents]
    const blob = new Blob(fileContents, { type: 'text/plain;charset=utf-8' })
    const url = window.URL || window.webkitURL
    const link = url.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = state.post.name + '.geojson'
    a.href = link
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  reset ({ commit, state }) {
    if (state.mapObject.getLayer('datasetPreview')) {
      state.mapObject.removeLayer('datasetPreview')
      state.mapObject.removeSource('datasetPreview')
    }
    if (state.mapObject.getLayer('samplesDatasetPreview')) {
      state.mapObject.removeLayer('samplesDatasetPreview')
      state.mapObject.removeSource('samplesDatasetPreview')
    }
    if (state.mapObject.getLayer('samplesDatasetPreviewOutline')) {
      state.mapObject.removeLayer('samplesDatasetPreviewOutline')
      state.mapObject.removeSource('samplesDatasetPreviewOutline')
    }
    commit('setStateProperty', { property: 'dataset', value: {} })
    commit('setStateProperty', { property: 'samplesDataset', value: {} })
  },
  initTranslationMap ({ commit, state }) {
    mapboxgl.accessToken = 'pk.eyJ1IjoibGVyZ3AiLCJhIjoiY2p4bmI1NzNzMGN0MTNjcGx4cjF4eDBtdSJ9.2C0FEHhNZ-jGd7jgIRTrEQ' // eslint-disable-line
    state.mapObject = new mapboxgl.Map({ // eslint-disable-line
      container: 'translationMap',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      style: 'https://api.maptiler.com/maps/da520b6d-739a-4646-888f-1d3f2b664f78/style.json?key=IuJ0D0R9S0Cjl4krhvr1',
      center: [state.farm.geolocation._longitude, state.farm.geolocation._latitude],
      zoom: 15
    })
    state.mapObject.addControl(new mapboxgl.NavigationControl()) // eslint-disable-line
  },
  initPlugin ({ commit, state, dispatch }, farmId) {
    commit('setStateProperty', { property: 'spinner', value: true })
    const timeStamp = new Date().getTime()
    const url = 'https://api.efficientvineyard.com/farm/' + farmId + '?timestamp=' + timeStamp
    axios.get(url).then(response => {
      response.data.id = farmId
      commit('setStateProperty', { property: 'farm', value: response.data })
      commit('setStateProperty', { property: 'spinner', value: false })
      dispatch('initTranslationMap')
      return true
    })
    // Get Root Folders
    // Get Root Datasets
  }
}
